import React, { useEffect } from "react"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import { useState } from "react"
import BackgroundImage from "gatsby-background-image"

const OurCollection = props => {
  const [active, setActive] = useState(0)

  return (
    <div>
      <div className="flex flex-wrap items-center justify-center lg:gap-10 sm:gap-5 gap-5">
        {props.array.map((item, index) => {
          return (
            <Fade bottom cascade key={index}>
              <div>
                <div
                  className={
                    active == index
                      ? "font-firs-semibold cursor-pointer text-salmon sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-salmon duration-200"
                      : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                  }
                  onClick={() => {
                    setActive(index)
                  }}
                >
                  {item.title}
                </div>
              </div>
            </Fade>
          )
        })}
      </div>
      <div className="mt-8">
        <Fade bottom>
          <div className="flex lg:flex-row flex-col sm:text-left text-center">
            <BackgroundImage
              {...props.array[active].cover}
              className="w-full lg:w-2/5 py-44"
            ></BackgroundImage>
            <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
              <div className="text-xl font-light mt-2 text-white max-w-lg">
                {props.array[active].line1}
              </div>
              <div className="text-xl font-light mt-2 text-white max-w-lg">
                {props.array[active].line2}
              </div>
              <div className="text-xl font-light mt-2 text-white max-w-lg">
                {props.array[active].line3}
              </div>
              <div className="text-xl font-light mt-2 text-white max-w-lg">
                {props.array[active].line4}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default OurCollection
