// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../../images/icons/textile_white.svg"
import intergrate_white from "../../images/icons/intergrate_white.svg"
import advance_white from "../../images/icons/advance_white.svg"

import textile_mint from "../../images/icons/textile_mint.svg"
import intergrate_mint from "../../images/icons/intergrate_mint.svg"
import advance_mint from "../../images/icons/advance_mint.svg"

//import svgs for how it works icons
import complex from "../../images/engineered-knit/platform-icons/complex.svg"
import complexwhite from "../../images/engineered-knit/platform-icons/complexwhite.svg"
import cost from "../../images/engineered-knit/platform-icons/cost.svg"
import costwhite from "../../images/engineered-knit/platform-icons/costwhite.svg"
import sustainability from "../../images/engineered-knit/platform-icons/sustainability.svg"
import sustainabilitywhite from "../../images/engineered-knit/platform-icons/sustainabilitywhite.svg"
import washable from "../../images/engineered-knit/platform-icons/washable.svg"
import washablewhite from "../../images/engineered-knit/platform-icons/washablewhite.svg"

// import our collection icons

import Circular_Knitting_dark_Blue from "../../images/engineered-knit/Icons/Our-collection/Circular_Knitting_dark_Blue.svg"
import Circular_Knitting_pink from "../../images/engineered-knit/Icons/Our-collection/Circular_Knitting_pink.svg"
import Flat_Knitting_dark_Blue from "../../images/engineered-knit/Icons/Our-collection/Flat_Knitting_dark_Blue.svg"
import Flat_Knitting from "../../images/engineered-knit/Icons/Our-collection/Flat_Knitting.svg"
import warp_Knit_Dark_blue from "../../images/engineered-knit/Icons/Our-collection/warp_Knit_Dark_blue.svg"
import warp_Knit_pink from "../../images/engineered-knit/Icons/Our-collection/warp_Knit_pink.svg"

//icons for how it works
import Composition from "../../images/flexible-battery/how-it-works-icons/Composition.svg"
import Construction from "../../images/flexible-battery/how-it-works-icons/Construction.svg"
import Power from "../../images/flexible-battery/how-it-works-icons/Power.svg"

import Functonalwhite from "../../images/thermal-regulation/icons/Functionalwhite.svg"
// import Machinewashablegreen from "../../images/thermal-regulation/icons/Machinewashablegreen.svg"

// import optionalitywhite from "../..//images/thermal-regulation/icons/optionalitywhite.svg"
import Machinewashablewhite from "../..//images/thermal-regulation/icons/Machinewashablewhite.svg"
import Failsafewhite from "../../images/thermal-regulation/icons/Failsafewhite.svg"
import OurCollection from "../../components/engineered-knit/our-collection"

//import platform slider

const EngineeredKnit = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    hero: convertToBgImage(getImage(data.hero)),
    baner_odlo: convertToBgImage(getImage(data.baner_odlo)),

    led: convertToBgImage(getImage(data.led)),
    a: getImage(data.grid_1),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    grid_4: convertToBgImage(getImage(data.grid_4)),
    grid_5: convertToBgImage(getImage(data.grid_5)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),

    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),

    //application images

    Connected: convertToBgImage(getImage(data.Connected)),
    Healthcare: convertToBgImage(getImage(data.Healthcare)),

    //how it works images

    Composition: convertToBgImage(getImage(data.Composition)),
    Construction: convertToBgImage(getImage(data.Construction)),
    Powering: convertToBgImage(getImage(data.Powering)),

    //capability images
    configure_img: convertToBgImage(getImage(data.configure_img)),
    tech_spec_img: convertToBgImage(getImage(data.tech_spec_img)),

    //consumer img
    Powering_1: convertToBgImage(getImage(data.Powering_1)),
    tmpControl_img: convertToBgImage(getImage(data.app6)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_knit_img: convertToBgImage(getImage(data.textile_img1)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),

    //application images

    con_health: convertToBgImage(getImage(data.app1)),
    con_tech: convertToBgImage(getImage(data.app2)),
    banner: convertToBgImage(getImage(data.app3)),
    performance: convertToBgImage(getImage(data.app4)),
    workwear: convertToBgImage(getImage(data.app5)),

    //circular knit images

    circular_capability: convertToBgImage(getImage(data.circular1)),
    circular_app: convertToBgImage(getImage(data.circular2)),
    circular_feature: convertToBgImage(getImage(data.circular3)),

    //flat knit images

    flat_app: convertToBgImage(getImage(data.flat1)),
    flat_capability: convertToBgImage(getImage(data.flat2)),
    flat_feature: convertToBgImage(getImage(data.flat3)),

    //warp knit images

    warp_app: convertToBgImage(getImage(data.warp1)),
    warp_capability: convertToBgImage(getImage(data.warp2)),
    warp_feature: convertToBgImage(getImage(data.warp3)),

    //palatform capabilities

    config: convertToBgImage(getImage(data.platform1)),
    Engineered_Knit_Technology_Specs: convertToBgImage(
      getImage(data.platform2)
    ),
  }

  const [activePlatform, setActivePlatform] = useState(0)
  const [activePlatform2, setActivePlatform2] = useState(0)
  const [active, setActive] = useState(0)

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_knit_img,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary form and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        " Adaptable battery for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const howItWorks1 = [
    {
      title: "Features",
      line1: "Complex 3D Structures",
      line2: "Zero Waste Manufacturing",
      line3: "Largest Facility in South Asia",
      line4: "Infinite design freedom",
      cover: images.flat_feature,
    },
    {
      title: "Capabilities",
      line1: "STOLL CMS 530 HP machine, Shima V Bed, Shima X Bed facilities",
      line2: "",
      line3: "",
      line4: "",
      cover: images.flat_capability,
    },
    {
      title: "Applications",
      line1: "+ Textile head-bands + sensors",
      line2:
        "+ Harnesses and straps contoured to body shape, Compression garments, 3D knit, and elbow guards. ",
      line3: "+ Watch straps and textile wrist devices + sensors",
      line4: "+ Integration into speakers and headphones for comfort",
      cover: images.flat_app,
    },
  ]

  const howItWorks2 = [
    {
      title: "Features",
      line1: "Seamless Technology",
      line2: "Uncompromised Stretch-ability",
      line3: "Softer Hand Feel",
      line4: "Affordable Construction",
      cover: images.circular_feature,
    },
    {
      title: "Capabilities",
      line1: "SM8 TOP2, SM8 TOP2V machine, and SM8 TR1 machine facilities",
      line2: "Variety of Interlocks and jersey styles to choose from",
      line3: "",
      line4: "",
      cover: images.circular_capability,
    },
    {
      title: "Applications",
      line1: "+ Compression garments",
      line2: "+ Heather and spacer fabrics as soft goods covering ",
      line3: "",
      line4: "",
      cover: images.circular_app,
    },
  ]

  const howItWorks3 = [
    {
      title: "Features",
      line1: "Highly Intricate Structure",
      line2: "Zero Seam Technology",
      line3: "High Measurement Consistency",
      line4: "",
      cover: images.warp_feature,
    },
    {
      title: "Capabilities",
      line1: "Textronic, Racheltronic and Jacquardtronic machines",
      line2: "",
      line3: "",
      line4: "",
      cover: images.warp_capability,
    },
    {
      title: "Applications",
      line1: "+ Compression garments",
      line2: "+ Heather and spacer fabrics as soft goods covering",
      line3: "",
      line4: "",
      cover: images.warp_app,
    },
  ]

  const platform = [
    {
      title: "Technology Specification",
      subtitle: "Technology Stacks",
      content1: "+ Heating panel integration using flat knitting technology ",
      content2: "+ Passive compression through 3D knitted structure ",
      content3:
        "+ Surface colorization using mutli-color thread feeding for structure creation",
      content4: "+ Sensing Technology integration ",
      content5: "",

      image: "../../images/brand/symbol-mint.png",
      cover: images.Engineered_Knit_Technology_Specs,
    },
    {
      title: "Capability",
      subtitle: "",
      content1:
        "Single covering, double covering, yarn braiding and package dying",
      content1_line_2:
        "Machines of HP 530 STOLL 7.2 machines. This is a multi gauge machine from",
      content2: "7gg – 14gg",
      content3: "Mach - 2XS",
      content4: " CMS30 ",
      content5: "Sheima Seiki Mach X2",
      content6: "wholegarment machine 15gg",
      image: "../../images/brand/symbol-mint.png",

      cover: images.config,
    },
  ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  const ourCollectionSlider = [
    {
      title: "Flat Knit ",
      image: Flat_Knitting_dark_Blue,
      image_active: Flat_Knitting,
    },
    {
      title: "Circular Knit",
      image: Circular_Knitting_dark_Blue,
      image_active: Circular_Knitting_pink,
    },
    {
      title: "Warp Knit",
      image: warp_Knit_Dark_blue,
      image_active: warp_Knit_pink,
    },
  ]

  return (
    <Layout>
      <Seo title="Engineered Knit" />
      <section>
        <BackgroundImage {...images.hero} className="h-screen bg-dusk">
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Engineered Knit
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Computerizing the weaving of yarn for complex construction
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20 text-center sm:text-left">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-salmon mb-4 uppercase text-center ">
                    ENABLING KNIT TECHNOLOGIES
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Complex Structure and Intelligent Design
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. Our platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-6xl leading-snug mt-4 text-dusk">
                    Leveraging Flat, Circular, and Warp knitting techniques to
                    create products built for optimal comfort and fit-to-form.
                    Knitting is our bread and butter at Softmatter– we are
                    responsible for numerous breakthroughs in smart yarn
                    knitting and engineered knit over the last few years.
                    Additionally, we are home to the largest Flatbed Knitting
                    facility in South Asia – a facility that fuels much of our
                    knit innovation.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8 text-dusk">
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={sustainability}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={sustainabilitywhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Sustainable
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Zero Waste Manufacturing
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={washable}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={washablewhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Seamless
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Zero Seam Technology with uncompromised stretchability
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={complex}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={complexwhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Complex Forms
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Infinite design freedom with complex Knit patterns
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={cost}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={costwhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Cost effective
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Single process manufacturing enables final product
                        fabric directly from machine
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="mt-24">
              <Fade bottom cascade>
                <div>
                  <div className="text-4xl font-light text-dusk mb-4 sm:text-left text-center">
                    Our Portfolio
                  </div>
                  <div className="text-2xl font-light text-dusk max-w-4xl sm:text-left text-center leading-snug">
                    We offer our knit expertise in conjunction with our active
                    technologies, combining these competencies as required.
                  </div>
                </div>
              </Fade>
              <div className="mt-14">
                <div className="flex flex-wrap items-center justify-center lg:gap-36 lg:pb-5 pb-0 gap-7">
                  {ourCollectionSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-dusk mb-14">
                          {/* <div className="uppercase text-xl lg:block flex justify-center font-light mb-4"> */}
                          {activePlatform == index ? (
                            <>
                              <img
                                src={item.image_active}
                                width={60}
                                alt="Softmatter"
                                className="cursor-pointer sm:w-24 w-16"
                                onClick={() => setActivePlatform(index)}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={item.image}
                                width={60}
                                alt="Softmatter"
                                className="cursor-pointer sm:w-24 w-16"
                                onClick={() => setActivePlatform(index)}
                              />
                            </>
                          )}
                          {/* </div> */}
                          <div
                            className={
                              activePlatform == index
                                ? "  text-xs sm:text-xl text max-w-sm text-salmon duration-100   text-center"
                                : "  text-xs sm:text-xl text max-w-sm duration-100  text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-salmon duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                {activePlatform == 0 && (
                  <>
                    <OurCollection array={howItWorks1} />
                  </>
                )}
                {activePlatform == 1 && (
                  <>
                    <OurCollection array={howItWorks2} />
                  </>
                )}
                {activePlatform == 2 && (
                  <>
                    <OurCollection array={howItWorks3} />
                  </>
                )}

                {/* <div className="flex flex-wrap items-center justify-center lg:gap-10 gap-5">
                  {howItWorks.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div
                            className={
                              active == index
                                ? "font-firs-semibold cursor-pointer text-salmon sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-salmon duration-200"
                                : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                            }
                            onClick={() => {
                              setActive(index)
                              hello()
                            }}
                          >
                            {item.title}
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <div className="mt-8">
                  <Fade bottom>
                    <div className="flex lg:flex-row flex-col sm:text-left text-center">
                      <BackgroundImage
                        {...howItWorks[active].cover}
                        className="w-full lg:w-2/5 py-44"
                      ></BackgroundImage>
                      <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                        <div>
                          <div className="flex sm:justify-start justify-center">
                            <div>
                              <img
                                src={howItWorks[active].image}
                                width={80}
                                alt="Softmatter"
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-2xl font-light mt-8 text-white">
                          {howItWorks[active].title}
                        </div>
                        <div className="text-xl font-light mt-2 text-white max-w-lg">
                          {howItWorks[active].content}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk">
          <div className=" pt-24">
            <Fade bottom cascade>
              <div className="text-white px-5">
                <div className="text-center text-2xl font-firs-thin uppercase  mb-4">
                  Our Engineered Knit
                </div>
                <div className="text-center text-5xl md:text-6xl mb-4">
                  Applications
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Our revolutionary proprietary breakthroughs in yarn and knit
                  technology provide us with design freedom, allowing us to
                  create sophisticated and stylish products across various
                  form-factors.
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.con_tech}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Technology
                      </div>
                      <div className="text-base max-w-xl">
                        Form-fitting acoustic fabric coverings for speaker
                        systems, textile wristbands, and soft-touch computer
                        peripherals.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.con_tech}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              {/* <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Workwear
                      </div>
                      <div className="text-base max-w-xl">
                        Creating comfortable apparel and harnesses for prolonged
                        usage, with thoughtful fit and modulus.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div> */}

              <div className="flex lg:flex-row flex-col ">
                <div className="lg:w-1/2">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.performance}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Outdoor and Workwear
                      </div>
                      <div className="text-base max-w-xl">
                        The creation of fabric tunnels in skiwear, to enable
                        wire or conductive yarn passthrough for active heating.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                {/* <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.performance}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div> */}
              </div>

              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2 lg:hidden block">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.Healthcare}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer Health
                      </div>
                      <div className="text-base max-w-xl">
                        Knit headbands for sleep and stress analysis, textile
                        finishes for bedroom-based medical equipment, and fabric
                        straps and harnesses for comfort.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 lg:block hidden">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.Healthcare}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Capabilities
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                We offer our knit expertise in conjunction with our active
                technologies, combining these competencies as required.
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-10 gap-5 flex-wrap">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            activePlatform2 == index
                              ? "font-firs-semibold cursor-pointer text-mint sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-mint duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                          }
                          onClick={() => {
                            setActivePlatform2(index)
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[activePlatform2].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-10 sm:p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[activePlatform2].subtitle}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content1}
                      </div>
                      <div className="text-base font-light mt-2 text-white max-w-2xl">
                        {platform[activePlatform2].content1_line_2}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content2}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content3}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content4}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content5}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content6}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  TECHNOLOGY WOVEN IN
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-4xl text-white">
                  Explore All Platforms
                </div>
              </Fade>
            </div>
            <div className="flex lg:flex-row flex-col mb-72 lg:mb-44">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-32 sm:mb-24 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Get Started
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-salmon  hover:bg-salmon/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query EngineeredKnitImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    hero: file(relativePath: { eq: "engineered-knit/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Composition: file(
      relativePath: { eq: "flexible-battery/Composition.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Construction: file(
      relativePath: { eq: "flexible-battery/Construction.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Powering: file(relativePath: { eq: "flexible-battery/Powering.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Powering_1: file(relativePath: { eq: "flexible-battery/Powering_1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Connected: file(relativePath: { eq: "flexible-battery/Connected.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Healthcare: file(relativePath: { eq: "flexible-battery/Healthcare.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    tech_spec_img: file(
      relativePath: { eq: "flexible-battery/tech_spec_img.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    configure_img: file(
      relativePath: { eq: "flexible-battery/configure_img.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    baner_odlo: file(
      relativePath: { eq: "thermal-regulation/baner_odlo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    patented_awarded: file(
      relativePath: { eq: "visibility/Patentetd_awarded.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app1: file(
      relativePath: { eq: "engineered-knit/Application/consumer_health.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app2: file(
      relativePath: { eq: "engineered-knit/Application/Consumer_Tech_Knit.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app3: file(
      relativePath: { eq: "engineered-knit/Application/EK_Banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app4: file(
      relativePath: {
        eq: "engineered-knit/Application/Performance_Enneered.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    app5: file(
      relativePath: {
        eq: "engineered-knit/Application/workwear_engineering_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    circular1: file(
      relativePath: {
        eq: "engineered-knit/Circular-Knit/cicurlar_Knit_capabilities.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    circular2: file(
      relativePath: {
        eq: "engineered-knit/Circular-Knit/Circular_Knit_Applications.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    circular3: file(
      relativePath: {
        eq: "engineered-knit/Circular-Knit/Circular_Knit_features.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    flat1: file(
      relativePath: { eq: "engineered-knit/Flat-Knit-images/application.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    flat2: file(
      relativePath: { eq: "engineered-knit/Flat-Knit-images/Capabilities.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    flat3: file(
      relativePath: { eq: "engineered-knit/Flat-Knit-images/Features.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    platform1: file(
      relativePath: {
        eq: "engineered-knit/Platform-Capabilities/Configurations.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    platform2: file(
      relativePath: {
        eq: "engineered-knit/Platform-Capabilities/Engineered_Knit_Technology_Specs.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    warp1: file(
      relativePath: {
        eq: "engineered-knit/Warp-Knit/application_Warp_knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    warp2: file(
      relativePath: {
        eq: "engineered-knit/Warp-Knit/Flat_Knit_capabilities.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    warp3: file(
      relativePath: { eq: "engineered-knit/Warp-Knit/warp_Knitfeatures.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img2: file(
      relativePath: {
        eq: "thermal-regulation/Platform-Capabilities/ResearchThermal.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img1: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default EngineeredKnit
